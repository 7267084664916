"use client";
import { FC, ReactElement } from "react";

import styled from "@emotion/styled";
import BackgroundContainer from "@/components/shared/BackgroundContainer";

interface Props {
  children: ReactElement;
}

export const Container = styled("div")`
  background-color: #171517;
  height: 100dvh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: -1;
  display: flex;
`;

const LayoutNotFound: FC<Props> = ({ children }) => {
  return (
    <Container>
      <BackgroundContainer>{children}</BackgroundContainer>
    </Container>
  );
};

export default LayoutNotFound;
